import React, {useEffect, useState} from "react"
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import instance from "../api/httpclient";
import {Button, Form} from "react-bootstrap";
import moment from "moment";

const Dashboard = () => {

  const [statistics, setStatistics] = useState({});
  const [showYesterdayMention, setShowYesterdayMention] = useState(true);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  useEffect(() => {
    getStatistics();
  }, []);

  const getStatistics = () => {
    setShowYesterdayMention(true);
    instance.get('/statistics/dashboard',
      {
        headers: {
          'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
        }
      })
      .then(function (response) {
        setStatistics(response.data.statistics);
      });
  };

  const getStatisticInPeriod = (event) => {
    setShowYesterdayMention(false);
    event.preventDefault();
    if (null !== start && null !== end) {
      instance.get('/statistics/dashboard?start=' + start + '&end=' + end,
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? `Bearer ${window.localStorage.getItem("token")}` : ''
          }
        })
        .then(function (response) {
          setStatistics(response.data.statistics);
        });
    }
  };

  const reset = (event) => {
    event.preventDefault();
    setStart(null);
    setEnd(null);
    getStatistics();
  };

  const getAccountStatistics = () => {
    if (statistics) {
      if (statistics.accountStatistic) {
        return (
          <tbody>
          <tr>
            <td>Active accounts</td>
            <td>{statistics.accountStatistic.numberOfActiveAccounts}</td>
            <td></td>
          </tr>
          <tr>
            <td>Deleted accounts</td>
            <td>{statistics.accountStatistic.numberOfDeletedAccounts}</td>
            <td></td>
          </tr>
          <tr>
            <td>New accounts {showYesterdayMention ? '(yesterday)' : ''}</td>
            <td>{statistics.accountStatistic.numberOfNewAccounts}</td>
            <td></td>
          </tr>
          </tbody>
        );
      }
    }
  };

  const getRewardStatistics = () => {
    if (statistics) {
      if (statistics.rewardStatistic) {
        return (
          <tbody>
          <tr>
            <td>Rewards available</td>
            <td>{statistics.rewardStatistic.numberOfRewardsAvailable}</td>
            <td></td>
          </tr>
          <tr>
            <td>Rewards downloaded</td>
            <td>{statistics.rewardStatistic.numberOfRewardsDownloaded}</td>
            <td></td>
          </tr>
          <tr>
            <td>Average rewards by user</td>
            <td>{statistics.rewardStatistic.averageNumberOfRewardsByUser}</td>
            <td></td>
          </tr>
          </tbody>
        );
      }
    }
  };

  const getReportStatistics = () => {
    if (statistics) {
      if (statistics.reportStatistic) {
          return (
            <tbody>
            <tr>
              <td><b> FR </b> </td>
              <td><b>{statistics.reportStatistic.totalReportStatistic.numberOfAccountCreated} </b> </td>
              <td><b>{statistics.reportStatistic.totalReportStatistic.numberOfActivatedAccount} </b> </td>
              <td><b>{statistics.reportStatistic.totalReportStatistic.numberOfActivatedBronzeAccount} </b> </td>
              <td><b>{statistics.reportStatistic.totalReportStatistic.numberOfActivatedSilverAccount} </b> </td>
              <td><b>{statistics.reportStatistic.totalReportStatistic.numberOfActivatedGoldAccount} </b> </td>
              <td><b>{statistics.reportStatistic.totalReportStatistic.numberOfActivatedPremiumAccount} </b> </td>
              <td><b>{statistics.reportStatistic.totalReportStatistic.numberOfRewardDownloaded} </b> </td>
            </tr>
            </tbody>
          );
      }
    }
  };

  return (
    <div className="container">
      <div className="row hdr">
        <h1>Dashboard</h1>
      </div>

      <Tabs defaultActiveKey="tab1" id="uncontrolled-tab-example">
        <Tab eventKey="tab1" title="Accounts">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th tabindex="0" aria-label="KPI sortable" class="sortable">
                KPI
              </th>
              <th tabindex="0" aria-label="Value sortable" class="sortable">
                Value
              </th>
            </tr>
            </thead>
            {getAccountStatistics()}
          </table>
        </Tab>
        <Tab eventKey="tab2" title="Rewards">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th tabIndex="0" aria-label="KPI sortable" className="sortable">
                KPI
              </th>
              <th tabIndex="0" aria-label="Value sortable" className="sortable">
                Value
              </th>
            </tr>
            </thead>
            {getRewardStatistics()}
          </table>
        </Tab>
        <Tab eventKey="tab3" title="Report">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th tabIndex="0" aria-label="Country" className="sortable">
                Country
              </th>
              <th tabIndex="0" aria-label="Value sortable" className="sortable">
                Number of accounts created
              </th>
              <th tabIndex="0" aria-label="Value sortable" className="sortable">
                Number of activated accounts
              </th>
              <th tabIndex="0" aria-label="Value sortable" className="sortable">
                Number of activated Bronze accounts
              </th>
              <th tabIndex="0" aria-label="Value sortable" className="sortable">
                Number of activated Silver accounts
              </th>
              <th tabIndex="0" aria-label="Value sortable" className="sortable">
                Number of activated Gold accounts
              </th>
              <th tabIndex="0" aria-label="Value sortable" className="sortable">
                Number of activated Premium accounts
              </th>
              <th tabIndex="0" aria-label="Value sortable" className="sortable">
                Number of rewards downloaded
              </th>
            </tr>
            </thead>
            {getReportStatistics()}
          </table>
        </Tab>
      </Tabs>

      <Form>
        <div className="row justify-content-md-center align-items-center">
          <div className="col-md-4">
            <Form.Group controlId="startDate">
              <Form.Label>Start date :</Form.Label>
              <Form.Control type="date" name='startDate' value={moment(start, moment.ISO_8601).format('YYYY-MM-DD')}
                            onChange={(event) => {
                              setStart(event.target.value)
                            }}/>
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group controlId="endDate">
              <Form.Label>End date :</Form.Label>
              <Form.Control type="date" name='endDate' value={moment(end, moment.ISO_8601).format('YYYY-MM-DD')}
                            onChange={(event) => {
                              setEnd(event.target.value)
                            }}/>
            </Form.Group>
          </div>
          <div className="col-md-2">
            <Button onClick={(event) => {
              getStatisticInPeriod(event)
            }}>
              Search
            </Button>
          </div>
          <div className="col-md-2">
            <Button onClick={(event) => {
              reset(event)
            }}>
              Reset
            </Button>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Dashboard

