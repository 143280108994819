import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  HydraAdmin,
  ResourceGuesser,
  ListGuesser,
  FieldGuesser,
  EditGuesser,
  InputGuesser,
  ShowGuesser,
  hydraDataProvider as baseHydraDataProvider,
  fetchHydra as baseFetchHydra,
  Filter, TextInput
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import API_ENTRY_POINT from "./constants";
import authProvider from "./api/authProvider";
import { ReferenceInput, AutocompleteInput } from "react-admin";
import MyLayout from './custom/structure/MyLayout';
import customRoutes from './custom/structure/Routes';
import UserList from './custom/guessers/UserList';
import OfferList from './custom/guessers/OfferList';
import CarModelList from './custom/guessers/CarModelList';
import CampaignList from './custom/guessers/CampaignList';
import DealerList from './custom/guessers/DealerList';
import CarList from './custom/guessers/CarList';
import RewardList from './custom/guessers/RewardList';
import EventList from './custom/guessers/EventList';
import ContactList from './custom/guessers/ContactList';
import Home from './pages/Home';


const entrypoint = API_ENTRY_POINT;
const fetchHeaders = { Authorization: `Bearer ${window.localStorage.getItem("token")}` };

let myHeaders = new Headers(fetchHeaders);
//myHeaders.append('pragma', 'no-cache');
//myHeaders.append('Cache-Control', 'no-cache');

const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: myHeaders,
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: myHeaders })
  .then(
    ({ api }) => ({ api }),
    (result) => {
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              <Route path="/" render={() => {
                if(window.localStorage.getItem("token")){
                  return window.location.reload();
                } else {
                  return <Redirect to="/login" />
                }
              }} />
            ],
          });

        default:
          return Promise.reject(result);
      }
    },
  );
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);


const DealerShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"dealerCode"} addLabel={true} />
    <FieldGuesser source={"name"} addLabel={true} />
    <FieldGuesser source={"address"} addLabel={true} />
    <FieldGuesser source={"city"} addLabel={true} />
    <FieldGuesser source={"country"} addLabel={true} />
  </ShowGuesser>
);


export default () => (
  <HydraAdmin
    dataProvider={ dataProvider }
    authProvider={ authProvider }
    entrypoint={ entrypoint }
    customRoutes={customRoutes}
    dashboard={Home}
    layout={MyLayout} >

    {/*<ResourceGuesser name={"car_models"} list={CarModelList} />
    <ResourceGuesser name={"users"} list={UserList} />
    <ResourceGuesser name={"dealers"} list={DealerList} show={DealerShow}/>
    <ResourceGuesser name={"cars"} list={CarList}/>
    <ResourceGuesser name={"offers"} list={OfferList} />
    <ResourceGuesser name={"campaigns"} list={CampaignList} />
    <ResourceGuesser name={"rewards"} list={RewardList}/>
    <ResourceGuesser name={"car_events"} list={EventList}/>
    <ResourceGuesser name={"contact_forms"} list={ContactList} />
    <ResourceGuesser name={"service_documents"} />
    <ResourceGuesser name={"paragraphs"} />
    <ResourceGuesser name={"static_pages"} />
    <ResourceGuesser name={"parameters"} />*/}
  </HydraAdmin>

);
